'use client';
import cn from 'classnames';
import { Column, Header, Row } from 'components/ui-kit';
import { memo, useCallback, useState } from 'react';
import { SubscriptionForm, SubscriptionSectionSpacer } from './components';
import styles from './subscription.module.scss';

type TSubscriptionProps = {
  onSubscribe: (email: string) => Promise<void>;
  formHeader?: JSX.Element;
};

export const Subscription = memo(function Subscription({
  onSubscribe,
  formHeader,
}: TSubscriptionProps): JSX.Element {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const afterSubscribedHandler = useCallback(() => {
    setIsSubscribed(true);
  }, []);

  return (
    <section className={styles.subscription}>
      <SubscriptionSectionSpacer />
      <div
        className={cn(styles.subscriptionContainer, {
          [styles.subscriptionContainerGridRowGap]: isSubscribed,
        })}
      >
        <Row>
          <Column
            offset={{ m: 1, l: 1, xl: 0 }}
            span={{ s: 10, m: 10, l: 4, xl: 6 }}
          >
            <div className={styles.subscriptionHeaderWrapper}>
              {isSubscribed ? (
                <Header level={2}>
                  Ура! Вы подписались
                  <br />
                  на нашу рассылку.
                </Header>
              ) : (
                <>
                  {formHeader ?? (
                    <Header level={2}>
                      Узнайте о новых поступлениях первым
                    </Header>
                  )}
                </>
              )}
            </div>
          </Column>
          <Column
            offset={{ m: 1, l: 1, xl: 0 }}
            span={{ s: 12, m: 10, l: 5, xl: 6 }}
          >
            {!isSubscribed && (
              <SubscriptionForm
                onSubscribe={onSubscribe}
                afterSubscribedHandler={afterSubscribedHandler}
              />
            )}
          </Column>
        </Row>
      </div>
      <SubscriptionSectionSpacer />
    </section>
  );
});

import { useCopyToClipboard } from '@brand/utils';
import { Promocodes } from '_shared';
import { Button, Header, Spacer, Typo } from 'components/ui-kit';
import Link from 'next/link';
import styles from './subscription-success.module.scss';

export type TSubscribeSuccessProps = {
  promocode: Promocodes;
  description?: JSX.Element;
  actionButton?: JSX.Element;
};

export const SubscriptionSuccess = ({
  promocode,
  description,
  actionButton,
}: TSubscribeSuccessProps): JSX.Element => {
  const [isCopied, copy] = useCopyToClipboard();

  return (
    <>
      <Header weight="600" level={3}>
        Спасибо за подписку!
      </Header>
      <Spacer size={16} />
      <Typo>Скопируйте промокод и используйте его при оформлении заказа.</Typo>
      <Spacer size={16} />
      <div className={styles.box}>
        <Typo className={styles.code} align="center" variant="2xl" weight="600">
          {promocode}
        </Typo>
        <Button
          onClick={() => void copy(promocode)}
          size="l"
          variant="secondary"
        >
          {isCopied ? 'Скопировано' : 'Скопировать'}
        </Button>
      </div>
      <Spacer size={16} />
      {description ?? (
        <Typo>
          Промокод одноразовый, но действует на все товары и любую сумму заказа.
          Так что позвольте себе всё, что захочется 😉
        </Typo>
      )}

      {actionButton ?? (
        <>
          <Spacer size={32} />
          <Link href="/">
            <Button size="xl" variant="primary">
              Начать покупки
            </Button>
          </Link>
        </>
      )}
    </>
  );
};

import { Spacer } from 'components/ui-kit';
import { useMedia } from 'hooks';
import { Breakpoint } from '../../../../_shared';

export const SubscriptionSectionSpacer = (): JSX.Element => {
  const breakpoint = useMedia();
  const spacerSize = breakpoint > Breakpoint.MEDIUM_SCREEN ? 100 : 60;

  return <Spacer size={spacerSize} />;
};
